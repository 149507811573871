<template>
  <main>
    <Navbar />

    <KoiBanner type="sub" :title="title" />

    <section :class="$style.sub">
      <template v-if="Number.isNaN(Number.parseInt(title))">
        <h6>
          This is <a href="/" @click.prevent>{{ title }}</a> page
        </h6>
      </template>
      <template v-else>
        <h6>{{ title }}</h6>
      </template>
    </section>

    <KoiFooter />
  </main>
</template>

<script>
import KoiBanner from "@/components/KoiBanner";
import KoiFooter from "@/components/KoiFooter";

import Navbar from "@/components/Navbar";

export default {
  name: "NotFound",
  props: {},
  components: {
    KoiBanner,
    KoiFooter,
    Navbar
  },
  computed: {
    title() {
      return this.$route.name.toUpperCase().replace(/-LANG$/, "");
    }
  },
  methods: {},
  mounted() {}
};
</script>

<style module>
.sub {
  padding: 0 8vw 4vw;
  text-align: left;
  & h6 {
    border-top: 1px solid #cbcbcb;
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 3.6rem 0 0;
    padding: 3.6rem 0 2rem;
    &:first-child {
      border-top-color: transparent;
      margin-top: 2rem;
    }
  }
  & p {
    font-size: 1.1rem;
    line-height: 1.5;
    text-align: justify;
  }
  & a {
    color: #690000;
  }
}
</style>
